// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "mlaaiapp-20200111233901-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://didxqlnce8mz4.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:6716484f-e770-4ce1-9617-4066c2ade309",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UrgZGczEC",
    "aws_user_pools_web_client_id": "6s3fboddnqr6d4edv3vrsdj010",
    "oauth": {},
    "aws_mobile_analytics_app_id": "beca96be96cc4695baa7ce462bfed13d",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "mlaaiapp0e2d003e2e394677a993b20b54024fe2233707-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dynamo5b8fe6f5-dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nfqwjf6gzzhlbm74w7bm2ckh2y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "api0b3f2b66",
            "endpoint": "https://jysieqbc49.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "XR": {
        "scenes": {
            "scene1": {
                "sceneConfig": {
                    "sceneId": "c17e011ca07143f2ba504c90272ba87a.scene",
                    "region": "us-west-2",
                    "projectName": "Drafts",
                    "url": "https://sumerian.us-west-2.amazonaws.com/20180801/projects/Drafts/release/authTokens?sceneId=c17e011ca07143f2ba504c90272ba87a.scene"
                }
            },
            "Mlaai1": {
                "sceneConfig": {
                    "sceneId": "9bab865a13634eddaa4ab2f2efaa2b7d.scene",
                    "region": "us-east-1",
                    "projectName": "Drafts",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Drafts/release/authTokens?sceneId=9bab865a13634eddaa4ab2f2efaa2b7d.scene"
                }
            },
            "Mlaai": {
                "sceneConfig": {
                    "sceneId": "9bab865a13634eddaa4ab2f2efaa2b7d.scene",
                    "region": "us-east-1",
                    "projectName": "Drafts",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Drafts/release/authTokens?sceneId=9bab865a13634eddaa4ab2f2efaa2b7d.scene"
                }
            },
            "MlaaiIntro": {
                "sceneConfig": {
                    "sceneId": "af2304edc8334160b5432b0ac5076b10.scene",
                    "region": "us-east-1",
                    "projectName": "Drafts",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Drafts/release/authTokens?sceneId=af2304edc8334160b5432b0ac5076b10.scene"
                }
            }
        }
    },
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Salli",
                    "LanguageCode": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
